import React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';

// CSS File Here
import 'swiper/css';
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationAR from "./locales/ar.json";
i18n
  .use(initReactI18next) // bind react-i18next to i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ar: {
        translation: translationAR,
      },
    },
    lng: "ar", // set the default language
    fallbackLng: "ar", // set the fallback language
    interpolation: {
      escapeValue: false, // not needed for react
    },
  });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);


